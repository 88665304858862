@import 'abstracts/vars';
@import 'abstracts/mixins';
@import 'base/fontawesome/variables';
@import 'base/fontawesome/mixins';


.toggle {
	details[open] summary:before {
		content: $fa-var-minus-square;
	}

	.toggle-header {
		&::-webkit-details-marker {
			display: none;
		}

		&:before {
			@include fa-icon;

			content: $fa-var-plus-square;
			position: absolute;
			font-size: 28px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		position: relative;
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		padding: 0 10px;
		background: $toggle-header-bg;
		cursor: pointer;

		&.show {
			display: inline-block !important;
		}

		.toggle-label {
			display: block;
			float: right;
			margin: 0 0 0 10px;
		}
	}

	.show {
		color: $toggle-show-color;
	}

	.toggle-content {
		margin-top: -1px;
	}

	&.mobile-navigation-switcher {
		position: absolute;
		text-align: right;
		z-index: 10;
	    top: 34px;
		right:10px;
		@include min-screen(lg) {
				display: none;
		}

		.toggle-header {
			position: static;

			.toggle-label {
				margin-left: 20px;
			}
		}

		details {
			summary:before {
				content: '';
				color: #fff;
				background-image: url(../images/icons/icon-menu.svg);
				height: 28px;
				width: 24px;
				background-repeat: no-repeat;
			}

			&[open] {
				summary:before {
					content: '';
					background-image: url(../images/icons/icon-close--black.svg);
					width: 1em;
					height: 1em;
					background-size: 100%;
					font-size: 1.8em;
					opacity: 0.6;
				}
			}
		}
	}
}
