@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.toggle details[open] summary:before {
  content: "";
}

.toggle .toggle-header {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 0 10px;
  background: #FFFFFF;
  cursor: pointer;
}

.toggle .toggle-header::-webkit-details-marker {
  display: none;
}

.toggle .toggle-header:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  font-size: 28px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.toggle .toggle-header.show {
  display: inline-block !important;
}

.toggle .toggle-header .toggle-label {
  display: block;
  float: right;
  margin: 0 0 0 10px;
}

.toggle .show {
  color: #878787;
}

.toggle .toggle-content {
  margin-top: -1px;
}

.toggle.mobile-navigation-switcher {
  position: absolute;
  text-align: right;
  z-index: 10;
  top: 34px;
  right: 10px;
}

@media (min-width: 992px) {
  .toggle.mobile-navigation-switcher {
    display: none;
  }
}

.toggle.mobile-navigation-switcher .toggle-header {
  position: static;
}

.toggle.mobile-navigation-switcher .toggle-header .toggle-label {
  margin-left: 20px;
}

.toggle.mobile-navigation-switcher details summary:before {
  content: '';
  color: #fff;
  background-image: url(../images/icons/icon-menu.svg);
  height: 28px;
  width: 24px;
  background-repeat: no-repeat;
}

.toggle.mobile-navigation-switcher details[open] summary:before {
  content: '';
  background-image: url(../images/icons/icon-close--black.svg);
  width: 1em;
  height: 1em;
  background-size: 100%;
  font-size: 1.8em;
  opacity: 0.6;
}
